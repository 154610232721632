import { getValueByKey } from ".";
import Apple from "../logos/Apple.svg";
import PlayMarket from "../logos/PlayMarket.svg";
// todo logos
import MobileApp from "../logos/MobileAppOneart.svg";
import MobileAppWhite from "../logos/MobileAppOneartWhite.svg";
// for oneart
var oneartDefaultLink = "https://oneart.page.link/?apn=oneart.digital&isi=1600729515&ibi=co.oneart";
var oneartIosDeepLink = undefined;
var oneartAndroidDeepLink = undefined;
var oneartExtensionLinkChrome = oneartDefaultLink;
export var oneartDefaultLinks = {
    ios: oneartIosDeepLink,
    android: oneartAndroidDeepLink,
    qr: undefined,
    extension: [
        {
            browser: "chrome",
            link: oneartExtensionLinkChrome !== null
                ? oneartExtensionLinkChrome || oneartDefaultLink
                : null,
        },
    ],
};
export var getOneArtQr = function (link) {
    return (
    // url
    //
    oneartDefaultLink +
        //
        // params
        //
        "&link=https%3A%2F%2Foneart.digital%2Fen%2F%3Fgetapp%3Dtrue%26action%3Dbrowser%26url%3D" +
        (link || encodeURIComponent(window.location.href)));
};
export var getOneArtIos = getOneArtQr;
export var getOneArtAndroid = getOneArtQr;
export var oneartwallet = {
    id: "oneartwallet",
    walletWaysToConnect: [
        {
            id: "extension",
            type: "extension",
            logo: {
                chrome: MobileApp,
            },
            name: "OneArt",
            options: {
                isCurrentBrowser: [["isFalse", "isFalse"]],
                installExtensionLink: function (links) {
                    return getValueByKey("oneartwallet", "extension")(links);
                },
                checkIsProviderExist: function () { return window.__venom && window.__venom.isOneArt; },
                hide: true,
            },
        },
        {
            id: "mobile",
            type: "mobile",
            logo: MobileApp,
            logoWhite: MobileAppWhite,
            name: "OneArt Mobile App",
            options: {
                qr: function (links) {
                    return getValueByKey("oneartwallet", "qr")(links);
                },
                devises: [
                    {
                        type: "ios",
                        img: Apple,
                        text: "iOS App",
                        deepLink: "https://apps.apple.com/app/id1600729515",
                        alt: "iOS",
                        storeId: "ios",
                    },
                    {
                        type: "android",
                        img: PlayMarket,
                        text: "Android App",
                        deepLink: "https://play.google.com/store/apps/details?id=oneart.digital",
                        alt: "Android",
                        storeId: "android",
                    },
                ],
            },
        },
        {
            id: "ios",
            type: "ios",
            logo: MobileApp,
            logoWhite: MobileAppWhite,
            name: "OneArt Mobile App",
            options: {
                text: "Click here to open App Store",
                deepLink: function (links) {
                    if (links === void 0) { links = oneartDefaultLinks; }
                    return getValueByKey("oneartwallet", "ios")(links);
                },
            },
        },
        {
            id: "android",
            type: "android",
            logo: MobileApp,
            logoWhite: MobileAppWhite,
            name: "OneArt Mobile App",
            options: {
                text: "Click here to open Google Play",
                deepLink: function (links) {
                    if (links === void 0) { links = oneartDefaultLinks; }
                    return getValueByKey("oneartwallet", "android")(links);
                },
            },
        },
    ],
};
